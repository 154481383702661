import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Alert,
  Box,
  Button,
  Collapse,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  MenuItem,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

function UserForm() {
  const navigate = useNavigate();
  const [ age, setAge ] = useState();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const [state, setState] = React.useState({
    open: false,
    isSuccess: false
  });
  const { open, isSuccess } = state;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    if (formData.first_name && formData.last_name && formData.email) {
      axios
        .post("https://reqres.in/api/users", formData)
        .then((res) => {
          console.log(res.data);
          setState({ open: true, isSuccess: true });
          setTimeout(() => {
            setState({ open: false, isSuccess: false });
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
          setState({ open: true, isSuccess: false });
          setTimeout(() => {
            setState({ open: false, isSuccess: false });
          }, 3000);
        });
    }
  };

  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="h6">Create User</Typography>
      <form onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column">
          <Grid sx={{ mt: 2, mb: 2 }}>
            <TextField
              fullWidth
              label="First Name"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Last Name"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
        </Box>
        <Grid sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Age"
              onChange={(e) => setAge(e.target.value)}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid sx={{ mb: 2 }}>
          <FormControl>
            <FormLabel>Gender</FormLabel>
            <RadioGroup defaultValue="female" sx={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel value="female" control={<Radio />} label="Female" />
              <FormControlLabel value="male" control={<Radio />} label="Male" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mr: 2 }}
          >
            Submit
          </Button>
          <Button onClick={() => navigate(-1)}>Go Back</Button>
        </Box>
      </form>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: "absolute",
        width: "100%",
        top: 150,
        transform: "translateY(-50%)",
        zIndex: 2001,
      }}>
        <Collapse in={open}>
          {open && isSuccess ?
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setState({ open: false, isSuccess: false });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2, boxShadow: "0px 0px 10px rgba(0, 255, 0, 0.5)" }}
            >
              Successfully created a new user!
            </Alert>
          : open && !isSuccess ?
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setState({ open: false, isSuccess: false });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2, boxShadow: "0px 0px 10px rgba(255, 0, 0, 0.5)" }}
            >
              Error creating a new user!
            </Alert>
          : null}
        </Collapse>
      </Box>
    </Box>
  );
}

export default UserForm;
