import React from 'react';
import ReactPlayer from 'react-player';
import { Grid, Typography } from '@mui/material';
// import logo from '../logo.svg';

export default function Video() {
  const [selectedVideo, setSelectedVideo] = React.useState('');
  const [isPlaying, setIsPlaying] = React.useState(false);

  const videos = [
    {
      id: 1,
      title: 'What is AWS? | Amazon Web Services',
      author: 'Artist 1',
      url: 'https://www.youtube.com/watch?v=a9__D53WsUs',
      channel: "Amazon Web Services"
    },
    {
      id: 2,
      title: 'Back to Basics: Deploy Your Code to Amazon EC2',
      author: 'Artist 2',
      url: 'https://www.youtube.com/watch?v=f2wvEQ_sWS8',
      channel: "Amazon Web Services"
    },
    {
      id: 3,
      title: 'Next Gen Stats | Amazon Web Services',
      author: 'Artist 3',
      url: 'https://www.youtube.com/watch?v=vwRwEWhmsnM',
      channel: "Amazon Web Services"
    },
    {
      id: 4,
      title: 'Back to Basics: Deploying Code to ECS',
      author: 'Artist 4',
      url: 'https://www.youtube.com/watch?v=9hCZYBs0h9I',
      channel: "Amazon Web Services"
    },
    {
      id: 5,
      title: 'F1 Insights powered by AWS | Alternative Strategy | Amazon Web Services',
      author: 'Artist 5',
      url: 'https://www.youtube.com/watch?v=6UsInj7lNOk',
      channel: "Amazon Web Services"
    }
  ];

  const handlePlayerClick = (url) => {
    setSelectedVideo(url);
    setIsPlaying(true)
  };

  React.useEffect(() => {
    setSelectedVideo('https://www.youtube.com/watch?v=a9__D53WsUs');
    setIsPlaying(true);
  }, []);

  return (
    <Grid container sx={{ p: { xs: 0, sm: 4 } }}> 
      <Grid item xs={12} sm={9} lg={9} xl={10} sx={{ display: { xs: "none", md: "block", xl: "block" }, pr: { sm: 4 } }}>
        <ReactPlayer
          url={selectedVideo}
          width='100%'
          height='640px'
          // light={<img src={logo} alt='Thumbnail' height={240} width={240} />}
          playing={isPlaying}
          loop={false}
          controls={true}
          volume={0.5}
          muted={false}
          playbackRate={1}
          style={{}}
          progressInterval={1000}
          playsinline={false}
          pip={false}
          stopOnUnmount={true}
          fallback={null}
          wrapper={'div'}
          previewTabIndex={0}
          config={{}}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
        <Typography variant="h5" sx={{ pt: { xs: 0, md: 2 }}}>
          {videos?.find((vid) => vid?.url === selectedVideo)?.title ?? ""}
        </Typography>
        <Typography><i>{videos?.find((vid) => vid?.url === selectedVideo)?.channel ?? ""}</i></Typography>
      </Grid>
      <Grid item xs={12} sm={3} lg={3} xl={2}>
        <Typography variant='h6' fontWeight={600} sx={{ m: { xs: 1, md: 0 }}}>Playlist</Typography>
        {videos?.map((video, index) => (
          <Grid title={video?.title} key={index} sx={{ mb: 2 }}>
            <ReactPlayer
              key={index}
              url={video.url}
              width='100%'
              height='100%'
              playing={selectedVideo !== video.url ? false : true}
              loop={false}
              controls={true}
              volume={0.5}
              muted={true}
              playbackRate={1}
              style={{}}
              progressInterval={1000}
              playsinline={false}
              pip={false}
              stopOnUnmount={true}
              fallback={null}
              wrapper={'div'}
              previewTabIndex={0}
              config={{}}
              onPlay={() => handlePlayerClick(video.url)} // Handle click event for the ReactPlayer component
              onPause={() => setIsPlaying(false)}
            />
            <Typography sx={{ pl: { xs: 1, md: 0 }, pr: { xs: 1, md: 0 }, fontWeight: 600 }}>{video?.title}</Typography>
            <Typography sx={{ pl: { xs: 1, md: 0 }, pr: { xs: 1, md: 0 }}}><i>{video?.channel}</i></Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
