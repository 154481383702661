import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import AudioPlayer, { 
  // RHAP_UI 
} from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

export default function Podcast() {
  const podcasts = [
    {
      id: 1,
      title: 'Baby Elephant Walk',
      artist: 'Henry Manc',
      url: 'https://www2.cs.uic.edu/~i101/SoundFiles/BabyElephantWalk60.wav'
    },
    {
      id: 2,
      title: 'Cantina Band',
      artist: 'John Williams',
      url: 'https://www2.cs.uic.edu/~i101/SoundFiles/CantinaBand3.wav'
    },
    {
      id: 3,
      title: 'Yodel Sound Effect',
      artist: 'Abhishek',
      url: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/123941/Yodel_Sound_Effect.mp3"
    },
    {
      id: 4,
      title: 'Fanfare Sound Effect',
      artist: 'Kanishk',
      url: 'https://www2.cs.uic.edu/~i101/SoundFiles/Fanfare60.wav'
    },
    {
      id: 5,
      title: 'Gettysburg Address',
      artist: 'Abraham Lincoln',
      url: 'https://www2.cs.uic.edu/~i101/SoundFiles/gettysburg10.wav'
    }
  ];

  return (
    <Box sx={{ p: 4 }}>
      {podcasts?.map((podcast, index) => {
        return <Grid sx={{ mb: 4, width: { md: '60%' } }}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Typography variant='h6' fontWeight={600} sx={{ mr: 0.5 }}>{podcast?.id}. {podcast?.title}</Typography>
            <Typography variant='subtitle1' fontWeight={400}>by {podcast?.artist}</Typography>
          </Box>
          <AudioPlayer
            key={index}
            autoPlay={false}
            src={podcast.url}
            volume={0.8}
            showSkipControls
            progressUpdateInterval={100}
          />
        </Grid>}
      )}
    </Box>
  )
}
