import React, { Component } from "react";
import { Box } from "@mui/material";

export default class NotFound extends Component {
  render() {
    return (
      <Box sx={{ m: 2 }}>
        <h1>404 Not Found</h1>
      </Box>
    );
  }
}
