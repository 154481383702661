import * as React from 'react';
import { Link, useNavigate } from "react-router-dom";
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ToggleColorMode from './ToggleColorMode';

const drawerWidth = 240;
const navItemsStart = [
  { label: 'Home', route: "/home" },
  { label: 'Video', route: "/video" },
  { label: 'Podcast', route: "/podcast" },
  { label: 'About Us', route: "/about-us" },
  { label: 'Contact Us', route: "/contact-us" }
];

function NavBar(props) {
  const history = useNavigate();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navItemsEnd = [ { label: `Hi, ${props?.username ? props?.username : "User"}`, route: "" }, { label: 'Logout', route: "" }];

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Media App
      </Typography>
      <Divider />
      <List>
        {[...navItemsStart].map((item, index) => (
          <Link to={item.route} key={index} style={{ width: '100%', textDecoration: "none" }}>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} onClick={props.handleLogout}>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  const [isCookieConsent, setIsCookieConsent] = React.useState(false);

  React.useEffect(() => {
    const myAwesomeCookieValue = getCookieConsentValue("myAwesomeCookieName2") === "true";
    setIsCookieConsent(myAwesomeCookieValue);
  }, []);

  return (
    <Box>
      <CookieConsent
        buttonStyle={{ borderRadius: "4px", color: "#d52b1e", fontSize: "16px", fontWeight: 600, border: "1px solid #d52b1e", background: "#FFFFFF" }}
        buttonText="I Agree"
        cookieName="myAwesomeCookieName2"
        declineButtonText="I Disagree"
        declineButtonStyle={{ borderRadius: "4px", color: "#d52b1e", fontSize: "16px", fontWeight: 600, border: "1px solid #d52b1e", background: "#FFFFFF" }}
        enableDeclineButton
        hideOnAccept
        onAccept={() => {
          Cookies.set("myAwesomeCookieName2", "true", { path: '/' });
          setIsCookieConsent(true);
        }}
        onDecline={() => {
          Cookies.set("myAwesomeCookieName2", "true", { path: '/' });
          setIsCookieConsent(true);
        }}
        style={{ position: "relative", color: "#303030", borderTop: "4px solid #d52b1e", background: "#FFFFFF", fontSize: "12px" }}
        visible={isCookieConsent !== true ? "show" : "hidden"}
      >
        This website uses cookies and other technologies to personalize content and ads on this and other websites and provide social media services. By clicking “I Agree” you consent to our use of tracking technologies and disclosure of tracking data to third party ad networks or social media companies for the purposes above. If you click “I Disagree” you can still use this website. <a href="https://privacynotice.lilly.com/" style={{ color: "#019EE5" }} target="_blank" rel="noreferrer">For more information about Lilly’s privacy practices, click here.</a>
      </CookieConsent>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar component="nav" sx={{ position: "relative", background: '#d52b1e' }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: "flex", flexGrow: 1, justifyContent: "space-between", alignItems: "center" }}>
              <Box sx={{ display: { xs: 'none', sm: 'block' }, justifyContent: "center", alignItems: "flex-start" }}>
                {navItemsStart.map((item, index) => (
                  <Button key={index} sx={{ color: '#fff' }} onClick={() => history(item.route)}>
                      {item.label}
                  </Button>
                ))}
              </Box>
              <Box sx={{ display: { xs: 'none', sm: 'block' }, justifyContent: "center", alignItems: "flex-end" }}>
                <Button>
                  <Avatar alt="Remy Sharp" src={props.avatar} />
                </Button>
                {navItemsEnd.map((item, index) => (
                  <Button key={index} sx={{ color: '#fff' }} onClick={item.label === "Logout" ? () => props.handleLogout() : () => {}}>
                      {item.label}
                  </Button>
                ))}
              </Box>
            </Box>
            <ToggleColorMode mode={props.mode} toggleColorMode={props.toggleColorMode} />
          </Toolbar>
        </AppBar>
        <nav style={{ position: "relative" }}>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
    </Box>
  );
}

NavBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default NavBar;
