import React, { useState } from "react";
import axios from "axios";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie-consent";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import Users from "./components/Users";
import EditUser from "./components/EditUser";
import CreateUser from "./components/CreateUser";
import NotFound from "./components/NotFound";
import ProtectedRoute from "./components/ProtectedRoute";
import NavBar from "./components/NavBar";
import Video from "./components/Video";
import Podcast from "./components/Podcast";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import LandingPage from "./components/LandingPage";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getLPTheme from './components/getLPTheme';

function App() {
  const navigate = useNavigate();
  const [isSignedIn, setIsSignedIn] = useState(
    localStorage.getItem("token") ? true : false
  );
  const [username, setUsername] = useState(localStorage.getItem("username") ?? "");
  const [avatar, setAvatar] = useState(localStorage.getItem("avatar") ?? "");
  const [validations, setValidations] = useState({ isError: false, message: "" });
  const [mode, setMode] = React.useState('light');
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const setCookie = (cName, cValue, expDays) => {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
  }

  const handleLogin = (event) => {
    event.preventDefault();
    setValidations({ isError: false, message: "" });
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });

    if(data.get("password") === "WebAnalytics2024") {
      axios
        .post(`https://reqres.in/api/login`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          window.dataLayer.push('event', 'user_login_success_event');
          const token = res.data.token;
          localStorage.setItem("token", token);
          setCookie('username', data.get("email"), 1);
          Cookies.set('myAwesomeCookieName2', "false", { path: '/' });

          axios.get(`https://reqres.in/api/users`)
          .then((res) => {
            const emailAddress = data.get("email");
            const user = res.data.data.find((user) => user.email === emailAddress);
            localStorage.setItem("username", user?.first_name ?? "");
            localStorage.setItem("avatar", user?.avatar ?? "");
            setUsername(user?.first_name ?? "");
            setAvatar(user?.avatar ?? "");
          });

          setIsSignedIn(true);
          navigate('/home');
        })
        .catch((err) => {
          console.log(err);
          if(err?.response?.data?.error) {
            setValidations({ isError: true, message: err?.response?.data?.error ?? "" })
          } else {
            console.log(err);
          }
        });
    } else {
      setValidations({ isError: true, message: "Wrong Password" })
    }
  };

  const handleRegister = (event) => {
    event.preventDefault();
    setValidations({ isError: false, message: "" });
    const data = new FormData(event.currentTarget);
    console.log({
      first_name: data.get("first_name"),
      email: data.get("email"),
      password: data.get("password"),
    });

    axios
      .post(`https://reqres.in/api/register`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        navigate('/')
      })
      .catch((err) => {
        console.log(err);
        if(err?.response?.data?.error) {
          setValidations({ isError: true, message: err?.response?.data?.error ?? "" })
        } else {
          console.log(err);
        }
      });
  };

  const handleLogout = () => {
    localStorage.clear();
    document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    Cookies.remove('myAwesomeCookieName2');
    setIsSignedIn(false);
  };

  return (
    <div>
		<p>Hi Iam from PRod 1</p>
      <ThemeProvider theme={LPtheme}>
        <CssBaseline />
        {isSignedIn ? <NavBar avatar={avatar} username={username} handleLogout={handleLogout} mode={mode} toggleColorMode={toggleColorMode} /> : null}
        <Routes>
          <Route path="/" element={<SignIn handleLogin={handleLogin} isSignedIn={isSignedIn} validations={validations} setValidations={setValidations} />} />
          <Route path="/register" element={<SignUp handleRegister={handleRegister} isSignedIn={isSignedIn} validations={validations} setValidations={setValidations} />} />
          <Route element={<ProtectedRoute isSignedIn={isSignedIn} />}>
            <Route path="/home" element={<LandingPage />} />
            <Route path="/video" element={<Video />} />
            <Route path="/podcast" element={<Podcast />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/create" element={<CreateUser />} />
            <Route path="/users/edit/:userId" element={<EditUser />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
