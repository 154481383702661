import React from 'react'
import { Grid, Typography } from '@mui/material';

export default function ContactUs() {
  return (
    <Grid container sx={{ p: 4 }}>
      <Typography variant='h4' sx={{ fontWeight: 600, color: "#d52b1e", mb: 2 }}>Contact Us</Typography>
      <Grid item>      
      <Typography sx={{ fontSize: 18, pb: 2 }}>Lilly is committed to helping you get the medicine you need. We understand that some people taking Lilly medicines may be experiencing loss of employment, reduced compensation and interruption of insurance coverage due to COVID-19. If you are having trouble paying for a prescription for your Lilly medicine, learn more below about solutions that may work for you. Please call us. We want to help.</Typography>
      <Typography sx={{ fontWeight: 600, pb: 2 }}>NOTE: For more information about Lilly's COVID-19 therapies authorized for emergency use, contact the Lilly COVID Hotline at 1-855-LillyC19 (1-855-545-5921).</Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontWeight: 600, color: "#303030" }}>Indianapolis, IN</span>
          <span>Lilly Global Headquarters</span>
          <span>Eli Lilly and Company</span>
          <span>Lilly Corporate Center</span>
          <span>Indianapolis, IN 46285</span>
          <span>+1-317-276-2000</span>
        </Typography>
      </Grid>
    </Grid>
  )
}
