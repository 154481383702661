import * as React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Typography } from "@mui/material";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  dataLayer: {
    page: 'users'
  },
  dataLayerName: 'PageDataLayer'
}

export default function Users() {
  const history = useNavigate();

  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    TagManager.dataLayer(tagManagerArgs);

    axios
      .get("https://reqres.in/api/users?page=2")
      .then((res) => {
        window.dataLayer.push('event', 'users_api_success_event');
        setUsers(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleDelete = (userId) => {
    axios
      .delete(`https://reqres.in/api/users/${userId}`)
      .then((res) => {
        const remainingUsers = users.filter((user) => user.id !== userId);
        setUsers(remainingUsers);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box>
      <Grid sx={{ m: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Grid>
            <Typography variant="h6">Users - {users.length}</Typography>
          </Grid>
          <Grid>
            <Button onClick={() => history(`/users/create`)}>
              Create User
            </Button>
          </Grid>
        </Box>
      </Grid>
      <Grid sx={{ m: 2 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ fontWeight: "bold" }}>User ID</TableCell>
                <TableCell align="left" sx={{ fontWeight: "bold" }}>First Name</TableCell>
                <TableCell align="left" sx={{ fontWeight: "bold" }}>Last Name</TableCell>
                <TableCell align="left" sx={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow
                  key={user.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{user.id}</TableCell>
                  <TableCell align="left">{user.first_name}</TableCell>
                  <TableCell align="left">{user.last_name}</TableCell>
                  <TableCell align="left">{user.email}</TableCell>
                  <TableCell align="right">
                    <EditIcon
                      sx={{ mr: 2 }}
                      onClick={() => history(`/users/edit/${user.id}`)}
                    />
                    <DeleteForeverIcon onClick={() => handleDelete(user.id)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
